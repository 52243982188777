import React, { Component } from 'react'
import Header from '../../custom-components/header' 
import '../../custom-components/scss/base.scss'
import '../../custom-components/scss/base.scss'
import CookieConsent from 'react-cookie-consent';
import { Link } from "gatsby"
import 'typeface-montserrat'


class Layout extends Component{
  
   
   render(){
    
   return (
        
        <div className=" min-h-full">
            
         <Header />
         <div className="mx-auto">
         {/* <Sidebar /> */}
          {this.props.children}
          
         </div>
          
         <footer />
          
         <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="terms_and_conditions"
            containerClasses = "bg-gray-700 text-white"
            buttonClasses	="rounded-lg bg-blue-600 text-white"
            expires={672}
            buttonStyle={{
              background: "blue-600",
              color: "white",
              fontWeight: "bolder",
              borderRadius: "rounded-lg",
            }}
            onAccept={() => {
            
          }}
                  
        >

          This website uses cookies to enhance the user experience.
          Please read our <Link to="/terms" className="hover:text-blue-400 text-blue-200">terms and condition </Link>
        </CookieConsent>
        </div>
      )
     }
  }
  
  export default Layout
  