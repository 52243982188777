import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import '../custom-components/navigation'
import Navigation from '../custom-components/navigation'





const Header = ({data})=>{
 const query = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`)
    return(
        <header className="">
          <Navigation />
             

        </header>
    )
}



export default Header