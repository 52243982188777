import React from "react"
import { Link } from "gatsby"
import '../custom-components/scss/nav.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import LogoWhite from '../assets/logowhite.svg'


const Navigation = props => {
  const menuItems = [{link: '/', name: 'Home'}, {link:'/blog', name: 'Blog'},{link:'/contact',name:'About me'}]
  return (

<div className="min-w-full shadow-md">
    
    <nav className="flex justify-between md:justify-around justify-center  items-center flex-wrap  ">
    <div className="flex flex-shrink-0 pt-2 md:mr-2 ml-2">
      <AniLink cover to='/' bg="#68D391" >
                    <LogoWhite className="h-16 " />
          
      </AniLink>    
  </div>
  <div className="block lg:hidden px-3 ">
    <AniLink cover to="/menupage" bg="#68D391" >
    <button className="flex items-center px-3 py-2 border rounded text-gray-800 border-gray-800 hover:text-black hover:border-black">
      <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
    </button>
    </AniLink>
  </div>
  <div id="menu" className="hidden w-full lg:block justify-center lg:items-center lg:w-auto lg:visible">
    <div className="text-lg lg:flex-grow">
   {
     menuItems.map(
       menuItem => <AniLink cover to={menuItem.link} bg="#4299E1" className="inline-block text-blue-600 font-bold hover:text-orange-500 text-lg p-3" activeClassName="text-orange-900" key={menuItem.name}> {menuItem.name} </AniLink>
     )
   }

      
    </div>
    
  </div>
</nav>
    
</div>
       
     
  )
}

   

export default Navigation